/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.SEOFooter {
  background: $greyLightestPlus;
  padding: 48px 0 34px;
}

.SEOFooterWrap {
  position: relative;
}

.SEOFooterLogo {
  line-height: 1;
  margin-bottom: 24px;
  text-align: center;

  svg {
    height: 29px;
    width: 164px;
  }
}

.SEOFooterContent {
  color: $grey;
  height: 271px;

  :global {

    .SEOFooterTextItem {
      display: inline-block;
      margin-bottom: 16px;
    }

    h2,
    h3,
    h4 {
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      margin-bottom: 5px;
    }

    h3,
    h4 {
      font-size: $fontSize-s;
    }

    h4 {
      font-weight: $font-weight-m;
    }

    p {
      font-size: $fontSize-s;
      letter-spacing: 0.5px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    b {
      font-size: $fontSize-s;
    }

    .SEOFooterColumns {
      column-gap: 21px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      a {
        text-decoration: underline;
      }
    }

    .SEOFooterHeader {
      font-weight: $font-weight-l;
      margin-bottom: 24px;
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
    }

    .SEOFooterSubHeader {
      font-size: $fontSize-sm;
      font-weight: $font-weight-l;
      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 18px;
    }

    .SEOFooterCenter {
      text-align: center;
    }

    ul, ol {
      padding-left: 20px;
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .SEOFooterContent {

    :global {

      .SEOFooterColumns {
        grid-template-columns: 1fr 1fr;

        .SEOFooterTextItem:last-of-type {
          grid-column: 1 / 3;
        }
      }

      .SEOFooterHeader {
        max-width: 535px;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .SEOFooterContent {

    :global {

      .SEOFooterColumns {
        grid-template-columns: 1fr;

        .SEOFooterTextItem:last-of-type {
          grid-column: unset;
        }
      }

      .SEOFooterHeader {
        max-width: 343px;
      }
    }
  }
}
