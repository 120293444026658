/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ShowMoreContainer {

  &.Active {

    .ShowMoreContent {
      height: auto;

      &:after {
        display: none;
      }
    }
  }
}

.ShowMoreContent {
  overflow: hidden;
  position: relative;

  &:after {
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #f5f5f5 100%);
    bottom: 0;
    content: '';
    height: 150px;
    left: 0;
    max-height: 100%;
    position: absolute;
    width: 100%;
  }

  &.ShowMoreWhite {

    &:after {
      background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, #ffffff 100%);
    }
  }
}

.ShowMoreLink {
  margin-top: 30px;
  text-align: center;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .ShowMoreLink {
    margin-top: 15px;
  }
}
